import Logo from "./images/actionHeroTop.svg";
import { IoCloseOutline } from "react-icons/io5";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import nvidia from "./images/startups/nvidia.svg";
import cisco from "./images/startups/cisco.svg";
import intel from "./images/startups/intel.svg";
import dell from "./images/startups/dell.svg";
import hp from "./images/startups/hp.svg";
import supermicro from "./images/startups/supermicro.svg";
import aws from "./images/startups/aws.svg";
import azure from "./images/startups/azure.svg";
import ibm from "./images/startups/ibm.svg";
import google from "./images/startups/google.svg";
import oracle from "./images/startups/oracle.svg";
import altair from "./images/startups/altair.svg";
import mongo from "./images/startups/mongo.svg";
import dynatrace from "./images/startups/dynatrace.svg";
import snowflake from "./images/startups/snowflake.svg";
import salesforce from "./images/startups/salesforce.svg";
import axios from "axios";

const imageData = [
  {
    id: "nvidia",
    img: nvidia,
  },
  {
    id: "cisco",
    img: cisco,
  },
  {
    id: "intel",
    img: intel,
  },
  {
    id: "dell",
    img: dell,
  },
  {
    id: "hp",
    img: hp,
  },
  {
    id: "supermicro",
    img: supermicro,
  },
  {
    id: "aws",
    img: aws,
  },
  {
    id: "azure",
    img: azure,
  },
  {
    id: "ibm",
    img: ibm,
  },
  {
    id: "google",
    img: google,
  },
  {
    id: "oracle",
    img: oracle,
  },
  {
    id: "altair",
    img: altair,
  },
  {
    id: "mongo",
    img: mongo,
  },
  {
    id: "dynatrace",
    img: dynatrace,
  },
  {
    id: "snowflake",
    img: snowflake,
  },
  {
    id: "salesforce",
    img: salesforce,
  },
];
const dummyEmployeesList = [
  { value: 50, label: "1-50" },
  { value: 100, label: "51-100" },
  { value: 150, label: "101-150" },
  { value: 200, label: "151-200" },
  { value: 250, label: "201-250" },
  { value: 300, label: "251-300" },
  { value: 350, label: "301-350" },
  { value: 400, label: "351-400" },
  { value: 450, label: "401-450" },
  { value: 500, label: "451-500" },
  { value: 1000, label: "501-1000" },
  { value: 2000, label: "1001-2000" },
  { value: 3000, label: "2001-3000" },
  { value: 4000, label: "3001-4000" },
  { value: 5000, label: "4001-5000" },
  { value: 10000, label: "5001-10000" },
  { value: 15000, label: "10001-15000" },
  { value: 25000, label: "20001-25000" },
  { value: 30000, label: "25001-30000" },
  { value: 35000, label: "30001-35000" },
  { value: 40000, label: "35001-40000" },
  { value: 45000, label: "40001-45000" },
  { value: 50000, label: "45001-50000" },
  { value: 75000, label: "50001-75000" },
  { value: 100000, label: "75001-100000" },
  { value: 150000, label: "100001-150000" },
  { value: 300000, label: "200001-300000" },
  { value: 400000, label: "300001-400000" },
  { value: 500000, label: "400001-500000" },
  { value: 1000000, label: "500001-1000000" },
];
const dummyContactPreferenceList = [
  { value: "phone call", label: "Phone Call" },
  { value: "text", label: "Text" },
  { value: "email", label: "Email" },
];
const initValue = {
  name: "",
  company: "",
  email: "",
  phone: "",
  preference: "",
  employees: "",
};
const Section = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState(initValue);

  const onChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const formSubmit = async () => {
    const data = {
      name: formData["name"],
      company_name: formData["company"],
      company_email: formData["email"],
      employees: formData["employees"],
      phone_number: formData["phone"],
      contact_preference: formData["preference"],
    };
    await axios
      .post(
        "https://whispering-citadel-11540-0a9768b9a869.herokuapp.com/https://be.devomw.com/omw/sales",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((data) => {
        console.log("res", data);
        setShow(false);
        setFormData(initValue);
      })
      .catch((e) => {
        console.log("error", e);
      });
    console.log(formData);
  };

  const InfiniteLooper = ({ speed, direction, children }) => {
    return (
      <div className="looper">
        <div className="looper__innerList">
          <div
            className="looper__listInstance"
            style={{
              animationDuration: `${speed}s`,
              animationDirection: direction === "right" ? "reverse" : "normal",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        "bg-[#030303] flex flex-col items-center overflow-x-hidden gap-[2rem]"
      }
    >
      <div
        className={
          "min-h-screen h-full flex flex-col gap-[32px] items-center w-full"
        }
        style={{
          backgroundImage: `linear-gradient(180deg, #020006 1.6%, #240E62 17.92%, #7245F2 39.04%, #8D68F9 58.93%, #CEBEFF 74.91%)`,
        }}
      >
        <div
          className={
            "flex flex-col items-center sm:gap-[35px] gap-[31px] sm:py-[65px] py-[50px] px-4 max-w-[1140px] text-[#EFF2FF]"
          }
        >
          <span
            className={
              "text-[39px] sm:text-5xl md:text-6xl lg:text-[90px] bg-clip-text text-transparent font-extrabold opacity-30 text-center leading-[120%]"
            }
            style={{
              backgroundImage:
                "linear-gradient(90deg, #FFF 22.98%, #914EFF 95.54%)",
            }}
          >
            Actions Speak Louder Than Words.
          </span>
          <div className={"flex flex-col gap-[18px] items-center"}>
            <span
              className={
                "text-[26px] sm:text-3xl md:text-4xl lg:text-[39px] font-extrabold bg-clip-text text-transparent text-center leading-[120%]"
              }
              style={{
                backgroundImage:
                  "linear-gradient(91deg, #FFF 75.55%, #E3C7FF 95.38%)",
              }}
            >
              Think AI Automated Actions. Think AI Action Hero.
            </span>
            <span
              className={
                "text-center text-[14px] sm:text-lg md:text-xl lg:text-[27px] font-medium leading-[147%]"
              }
            >
              Over 100+ plug and play automations, activated by Voice or Text,
              we bring your AI solutions to life. You dream up what to automate
              — AI Action Hero will turn it into reality.
            </span>
          </div>
        </div>
        <div
          className={
            "p-10 w-[calc(100vw_*_951_/_320)] h-[450px] sm:w-[calc(100vw_*_1853_/_1524)] sm:h-[550px] rounded-bl-none rounded-br-none border-transparent outline-none bg-black overflow-hidden shadow-[0px_-40px_4px_0px_#6644BB] flex flex-col items-center"
          }
          style={{
            borderRadius: "50% / 100%",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <div
            className={
              "bg-[#030303] shadow-[0px_0px_30px_0px_#FFF_inset] sm:shadow-[0px_0px_30px_0px_#FFF_inset] py-[20px] sm:py-[40px] w-[170vw] sm:w-[calc(100vw_/_1.2)] h-[500px] rounded-[50%] gap-4 sm:gap-[30px] flex flex-col items-center"
            }
          >
            <div className={"flex flex-col items-center"}>
              <img src={Logo} alt={"Logo"} width={35} />
              <div
                className={
                  "flex flex-col items-center mt-4 sm:m-[27px] gap-[13px] sm:gap-[19px]"
                }
              >
                <span
                  className={
                    "text-xl text-white sm:text-2xl md:text-3xl lg:text-[36px] font-bold"
                  }
                >
                  AI Action Hero
                </span>
                <p
                  className={
                    "text-base w-[70vw] sm:w-full sm:text-base md:text-xl lg:text-[30px] font-semibold text-center opacity-[0.5] text-[#EFF2FF]"
                  }
                >
                  Works With Startups, Academic Institutions, and Enterprises.
                </p>
              </div>
            </div>
            <div
              className={
                "flex flex-col px-4 relative w-full overflow-hidden h-[50px] justify-center"
              }
            >
              <InfiniteLooper speed={40} direction="left">
                {imageData.map((image) => (
                  <img
                    key={image.id}
                    src={image.img}
                    alt={`${image.id}-img`}
                    className={
                      "flex items-center gap-[68px] px-4 mr-4 h-[28px]"
                    }
                  />
                ))}
                {imageData.map((image) => (
                  <img
                    key={image.id}
                    src={image.img}
                    alt={`${image.id}-img`}
                    className={
                      "flex items-center gap-[68px] px-4 mr-4 h-[28px]"
                    }
                  />
                ))}
              </InfiniteLooper>
            </div>
            <div className={"w-screen flex items-center justify-center"}>
              <p
                className={
                  "w-[66vw] flex flex-col xl:flex-row justify-center items-center sm:w-full sm:flex text-base sm:text-lg md:text-xl lg:text-[24px] font-bold text-center text-[#EFF2FF] mt-1 sm:mt-2"
                }
              >
                We Work With Everyone.
                <span className={"text-[#AFA0FF]"}>
                  {" "}
                  Welcome To Our Server Agnostic Solution.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            "bg-black w-full mt-[-32px] pt-[60px] sm:pt-[240px] flex justify-center"
          }
        >
          <div
            className={
              "sm:p-[55px] p-4 mx-4 bg-[#111121] flex flex-col sm:gap-[30px] gap-[20px] items-center relative sm:top-[-200px] top-0 mb-8 rounded-[22px]"
            }
            style={{
              boxShadow:
                "#7E73CC 0px 0px 0px 5px, rgb(67 57 160) 0px 0px 0px 10px, rgb(27 40 67) 0px 0px 0px 16px",
            }}
          >
            <span
              className={
                "text-[18px] text-white sm:text-2xl md:text-3xl lg:text-[42px] font-bold"
              }
            >
              Skip Our Waiting List
            </span>
            <button
              className={
                "text-white text-[10px] sm:rounded-[10px] rounded-[4px] sm:px-[31px] px-[11px] sm:py-[17px] py-[6px] sm:text-lg md:text-xl lg:text-[24px] font-bold flex flex-row gap-[20px] items-center"
              }
              style={{
                background:
                  "linear-gradient(180deg, #602DF0 28.26%, #321681 94.2%)",
              }}
              onClick={() => setShow(true)}
            >
              Nerds Come First / Click To Schedule
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 15 25"
                fill="none"
                className={"sm:w-[15px] w-[10px] sm:h-[25px] h-[15px]"}
              >
                <path
                  d="M2 2L12.5 12.5L2 23.5"
                  stroke="white"
                  strokeWidth="3.2"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {show ? (
        <div
          className={
            "fixed w-screen h-screen z-10 top-0 left-0 overflow-y-scroll bg-[#00000099] flex justify-center items-center backdrop-blur-[3px]"
          }
        >
          <div
            className={
              "bg-[#030303] border-2 border-solid border-[#3d3455] relative rounded-[30px] shadow-[0px_4px_64px_0px_#021426] p-4 sm:p-9 w-full max-w-[1000px] flex flex-col justify-center items-center"
            }
          >
            <div
              className={
                "w-full bg-[#602DF0] absolute rotate-[155deg] blur-[135px] h-[120px] opacity-75"
              }
            />
            <div className={"flex w-full justify-end items-center"}>
              <IoCloseOutline
                color="#fff"
                size={32}
                onClick={() => setShow(false)}
                className={"cursor-pointer"}
              />
            </div>
            <p
              className={
                "text-2xl sm:text-[70px] sm:leading-none font-extrabold bg-gradient-to-r from-[#C9D6FF] via-[#BCBBFF] to-[#AD9CFF] inline-block text-transparent bg-clip-text"
              }
            >
              Contact <span className={"text-white"}>Sales</span>
            </p>
            <p
              className={
                "text-base sm:text-xl font-semibold text-[#94A3B8] mt-2 sm:mt-6"
              }
            >
              60 Minute Call Back Guaranteed
            </p>
            <div
              className={
                "mt-8 border-[#ffffff33] border border-solid w-full rounded-[18px] p-4 sm:p-[54px] z-[1]"
              }
            >
              <div
                className={
                  "grid grid-cols-1 sm:grid-cols-2 gap-x-[68px] gap-y-5 sm:gap-y-[45px]"
                }
              >
                <div className={"flex flex-col gap-4"}>
                  <label
                    htmlFor={"name"}
                    className={
                      "text-white text-[10px] sm:text-base md:text-lg lg:text-xl font-semibold"
                    }
                  >
                    Your Name
                  </label>
                  <input
                    id={"name"}
                    className={`text-white sm:rounded-[14px] rounded-[6px] sm:py-3.5 py-2 px-4 outline-0 text-[10px] sm:text-base md:text-lg lg:text-xl border border-solid border-[#CBCCD23D] bg-transparent`}
                    placeholder={"Enter Your Name"}
                    value={formData.name}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className={"flex flex-col gap-4"}>
                  <label
                    htmlFor={"company"}
                    className={
                      "text-white text-[10px] sm:text-base md:text-lg lg:text-xl font-semibold"
                    }
                  >
                    Company Name
                  </label>
                  <input
                    id={"company"}
                    className={`text-white sm:rounded-[14px] rounded-[6px] sm:py-3.5 py-2 px-4 outline-0 text-[10px] sm:text-base md:text-lg lg:text-xl border border-solid border-[#CBCCD23D] bg-transparent`}
                    placeholder={"Enter Your Company Name"}
                    value={formData.company}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className={"flex flex-col gap-4"}>
                  <label
                    htmlFor={"email"}
                    className={
                      "text-white text-[10px] sm:text-base md:text-lg lg:text-xl font-semibold"
                    }
                  >
                    Company Email
                  </label>
                  <input
                    id={"email"}
                    className={`text-white sm:rounded-[14px] rounded-[6px] sm:py-3.5 py-2 px-4 outline-0 text-[10px] sm:text-base md:text-lg lg:text-xl border border-solid border-[#CBCCD23D] bg-transparent`}
                    placeholder={"Enter Your Company Email"}
                    value={formData.email}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className={"flex flex-col gap-4"}>
                  <label
                    htmlFor={"phone"}
                    className={
                      "text-white text-[10px] sm:text-base md:text-lg lg:text-xl font-semibold"
                    }
                  >
                    Phone Number
                  </label>
                  <input
                    id={"phone"}
                    className={`text-white sm:rounded-[14px] rounded-[6px] sm:py-3.5 py-2 px-4 outline-0 text-[10px] sm:text-base md:text-lg lg:text-xl border border-solid border-[#CBCCD23D] bg-transparent`}
                    placeholder={"Enter Your Phone Number"}
                    value={formData.phone}
                    onChange={onChange}
                    type={"tel"}
                    required
                  />
                </div>
                <div className={"flex flex-col gap-4"}>
                  <label
                    htmlFor={"preference"}
                    className={
                      "text-white text-[10px] sm:text-base md:text-lg lg:text-xl font-semibold"
                    }
                  >
                    Contact Preference
                  </label>
                  <select
                    name="preference"
                    id="preference"
                    className={` ${
                      formData.preference === ""
                        ? "text-[#9f9f9f]"
                        : "text-white"
                    } bg-no-repeat appearance-none sm:rounded-[14px] bg-dropDownImg focus:bg-upDropImg rounded-[6px] sm:py-3.5 py-2 px-4 outline-0 text-[10px] sm:text-base md:text-lg lg:text-xl border border-solid border-[#CBCCD23D] bg-transparent`}
                    style={{
                      backgroundSize: "1rem",
                      backgroundPosition: "right 0.7rem center",
                    }}
                    onChange={onChange}
                  >
                    <option
                      value={""}
                      className={"bg-white text-black hover:text-white"}
                    >
                      Select Contact Preference
                    </option>
                    {dummyContactPreferenceList?.map((preference) => (
                      <option
                        value={preference.value}
                        className={"bg-white text-black hover:text-white"}
                        key={`preference-${preference.value}`}
                      >
                        {preference.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={"flex flex-col gap-4"}>
                  <label
                    htmlFor={"employees"}
                    className={
                      "text-white text-[10px] sm:text-base md:text-lg lg:text-xl font-semibold"
                    }
                  >
                    Employees
                  </label>
                  <select
                    name="employees"
                    id="employees"
                    className={`${
                      formData.employees === ""
                        ? "text-[#9f9f9f]"
                        : "text-white"
                    } bg-no-repeat appearance-none sm:rounded-[14px] bg-dropDownImg focus:bg-upDropImg rounded-[6px] sm:py-3.5 py-2 px-4 outline-0 text-[10px] sm:text-base md:text-lg lg:text-xl border border-solid border-[#CBCCD23D] bg-transparent`}
                    style={{
                      backgroundSize: "1rem",
                      backgroundPosition: "right 0.7rem center",
                    }}
                    onChange={onChange}
                  >
                    <option
                      value={""}
                      className={"bg-white text-black hover:text-white"}
                    >
                      Select Employee Range
                    </option>
                    {dummyEmployeesList?.map((employees) => (
                      <option
                        value={employees.value}
                        className={"bg-white text-black hover:text-white"}
                        key={`employees-${employees.value}`}
                      >
                        {employees.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={"w-full flex justify-center items-center"}>
                <button
                  className={
                    "text-white w-full max-w-[254px] h-10 sm:h-[68px] rounded-xl mt-10 text-base sm:text-xl bg-gradient-to-b from-[#602DF0] to-[#321681] flex justify-center items-center gap-4"
                  }
                  onClick={formSubmit}
                >
                  Submit{" "}
                  <IoIosArrowForward color={"white"} className={"text-xl"} />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Section;
