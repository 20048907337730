import Section from "./section";
import ActionHeroImg from "./images/ActionHero.svg";

const App = () => {
  return (
    <div className={"w-full overflow-x-auto"}>
      <div
        className={
          "h-[68px] sm:h-[96px] w-full flex justify-center items-center bg-gradient-to-b from-[#030303] to-[#1C1C1C]"
        }
      >
        <div
          className={
            "w-full max-w-[1140px] flex justify-center sm:justify-start items-center"
          }
        >
          <img
            src={ActionHeroImg}
            className={"w-[160px] h-10 sm:w-[241px] sm:h-[60px]"}
            alt={""}
          />
        </div>
      </div>
      <Section />
    </div>
  );
};
export default App;
